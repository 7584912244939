@import url(https://fonts.googleapis.com/css?family=Montserrat);
.PAGEFOUR-GRAFICO-TOP {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* flex-wrap: wrap; */
}

.PAGEFOUR-GRAFICO-CARD-ONE {
  height: 97%;
  width: 100%;
  background-color: white;
  /* border-radius: 15px; */
  overflow: hidden;
  /* border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; */
}
.PAGEFOUR-GRAFICO-CARD-ONE-CONTENT {
  display: flex;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.GRAPH-TABLE-HEADER {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 10px;
  color: white;
  background-color: rgb(43, 44, 67);
  align-items: center;
}

.GRAPHS-FOUR-TABLE {
  width: 100%;
  height: 7%;
  display: flex;
  flex-wrap: wrap;
}

.GRAPHS-FOUR-TABLE-CONTENT {
  height: 80%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.GRAPHS-FOUR-TABLE-ACTION {
  height: 80%;
  width: 100%;
}
.GRAPHS-FOUR-TABLE-ACTION button {
  border-radius: 50px;
}
.GRAPHS-FOUR-TABLE-BUTTONS {
  width: 150px;
  display: inline-block;
  overflow: auto;
  white-space: nowrap;
  margin: 0px auto;
}
.GRAPHS-FOUR-TABLE-BUTTONS-HIDDEN {
  display: none;
}
.GRAPHS-FOUR-CONTENT-DATA {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 10px;
  height: 10%;
  border-bottom-style: solid;
  /* max-width: 12.5%; */
  border-bottom-width: 1px;
}
.GRAPHS-FOUR-CONTENT-DATA div {
  max-width: 12.5%;
  font-size: 10px;
  min-width: 12.5%;
  text-align: center;
}
.GRAPH-TABLE-HEADER div {
  max-width: 12.5%;
  min-width: 12.5%;
  display: flex;
  justify-content: center;
  text-align: center;
}

#bars{
    /* width: 50%; */
  }
  #bars div.line-pai{
    /* width: 450px; */
    display: flex;
    justify-content: space-between;
    padding-bottom: 2%;
    box-sizing: border-box;
    align-items: center;
  }
  #bars div.line-pai .line-text {
    text-align: left;
    /* width: 20%; */
    font-size: 10px;
    white-space: nowrap;
    width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
}
  #bars div.line-pai .line{
   display: flex;
   width: 200px;
   color:#fff;
   justify-content: center;
   height: 40px;
  
  }
  
.activePagination{
    z-index: 1;
    color: #fff!important;
    background-color: #4ba846!important;
    border-color: #4ba846!important;
}
.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #2b2c42;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.IMPORT {
  display: flex;
  justify-content: left;
  height: 100%;
  width: 100%;
}
.ERROR-IMPORT {
  z-index: 9000;
}
.IMPORT-LEFT {
  width: 25%;
  /* padding-left: 5%; */
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(248, 248, 248, 1);
  border-right: 1px solid rgba(128, 129, 132, 0.28);
  position: relative;
}
.IMPORT-LEFT-HEADER {
  height: 30%;
  background: white;
  width: 100%;
  padding-left: 5%;
  padding-top: 15%;
}
.HEADER-INFO p {
  color: rgba(208, 208, 208, 1);
}
.HEADER-INFO h1 {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 30px;
}
.IMPORT-LEFT-FORM {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 60%;
  padding-top: 5%;
}
.IMPORT-LEFT-FROM-NAME {
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);

  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(248, 248, 248, 1);
  background-clip: padding-box;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.IMPORT-LEFT-FROM-OPERATION {
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0);

  display: block;
  width: 98%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(248, 248, 248, 1);
  background-clip: padding-box;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.IMPORT-LEFT-FROM-LOGO {
  margin-bottom: 7%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  background-color: #fff;
  width: 100%;
  text-align: left;
  color: rgb(119, 120, 137);
  height: 33px;
}
.IMPORT-LEFT-FORM-OPERATIONS {
  padding-left: 5%;
  display: flex;
  margin: 0;
  align-items: center;
  width: 100%;
  justify-content: left;
  padding: 0;
  height: 10%;
  padding-left: 5%;
  padding-top: 1%;
}
.IMPORT-LEFT-BUTTON {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
}
.IMPORT-LEFT-BUTTON button {
  width: 200px;
  height: 50px;
  border-radius: 30px;
  padding: 0;
}
.IMPORT-RIGHT {
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow-y: hidden;
}
.IMPORT-RIGHT-BLOCK {
  background: rgba(56, 51, 51, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 284;
}
.IMPORT-RIGHT-BUTTONS {
  height: 8%;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: rgba(248, 248, 248, 1);
}
.IMPORT-RIGHT-BUTTON-1 {
  width: 228px;
}
.IMPORT-RIGHT-BUTTON-2 {
  width: 228px;
}
.IMPORT-RIGHT-BUTTON-3 {
  width: 228px;
}
.IMPORT-RIGHT-HEADER {
  height: 30%;
  background: white;
  width: 100%;
  padding-left: 5%;
  padding-top: 10%;
}
.IMPORT-RIGHT-HEADER p {
  color: rgba(208, 208, 208, 1);
}
.IMPORT-RIGHT-FORM {
  padding-top: 2.5%;
  width: 100%;
  height: 42%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(248, 248, 248, 1);
  flex-wrap: wrap;
  overflow-y: scroll;
}
.IMPORT-BUTTON-MIDDLE {
  margin-left: 1%;
  margin-right: 1%;
}
.IMPORT-RIGHT-BUTTONS-ACTION {
  height: 30%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.IMPORT-RIGHT-BUTTON {
  height: 10%;
  background-color: rgba(248, 248, 248, 1);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.IMPORT-LEFT-BUTTON-STOP-IMPORT {
  border-radius: 25px;
  width: 200px;
  height: 50px;
}
.IMPORT-LEFT-BUTTON-STOP-SALVAR {
  border-radius: 25px;
  width: 200px;
  height: 50px;
}
.operations-info {
  padding-left: 10%;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 2%;
  padding-top: 1.5%;
  padding-bottom: 1%;
  width: 100%;
}
.operations-info label {
  color: #808184;
  font-size: 16px;
}
.operations-options {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
}
.IMPORT-RIGHT-Button {
  display: flex;
  height: 10%;
  justify-content: center;
  align-items: center;
}

.Upload {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
  padding-left: 10%;
  padding-right: 10%;
}

.Content {
  display: flex;
  flex-direction: row;
  /* padding-top: 16px; */
  box-sizing: border-box;
  width: 100%;
}

.Files {
  display: flex;
  align-items: center;
  margin-left: 32px;
  /* align-items: flex-start; */
  justify-items: flex-start;
  flex: 1 1;
  overflow-y: auto;
}

.Actions {
  display: flex;
  flex: 1 1;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  /* margin-top: 32px; */
}

.Title {
  margin-bottom: 32px;
  color: #555;
}

.Filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}

.Row {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 32px;
}

.ProgressWrapper {
  display: block;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  margin:0;
}

button {
  font-family: "Roboto medium", sans-serif;
  font-size: 14px;
  display: inline-block;
  height: 36px;
  min-width: 88px;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 2px;
  background: rgb(42, 44, 67);
  color: #fff;
  outline: 0;
}

button:disabled {
  background: rgb(189, 189, 189);
  cursor: default;
}

.Dropzone {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(42, 44, 67);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}
.FileInput {
  display: none;
}
.Highlight {
  background-color: rgba(23, 173, 238, 0.102);
}
.icon-upload-file::before{
  content: "\e907";
  font-size: 50px;
  color: rgb(42, 44, 67);
}
.icon-upload-file-hover::before{
  content: "\e907";
  font-size: 50px;
  color: rgb(254, 202, 49)
}
.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgba(23, 173, 238, 0.102);
  border-radius: 5px;
}

.Progress {
  background-color: rgb(42, 44, 67);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.SQL {
  width: 100%;
  display: flex;
  -webkit-flex-wrap: wrap;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.SQL-HEADER {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.SQL-CONNECT {
  width: 100%;
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: space-evenly;
}
.SQL-AUTH {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-left: 5%;
  margin-right: 5%;
  align-items: center;
}
.SQL-BODY {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  align-items: center;
  height: 70%;
}
.SQL button{
  width: 200px;
  height: 50px;
  border-radius: 30px;
  padding: 0;
  margin-top: 1%;
}
.SQL-INPUT-ONE {
  width: 100%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  padding-left: 1%;
}
.SQL-INPUT-TWO {
  width: 40%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  padding-left: 1%;
}
.SQL-INPUT-TWO-SELECT {
  display: block;
  width: 100%;
  word-wrap: normal;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  outline: 0;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border-color: rgba(128, 129, 132, 0.28);
  background-clip: padding-box;
  /* border: 1px solid #ced4da; */
  border-width: 0 0 2px;
  /* border-radius: 0.25rem; */
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.SQL-TEXT-AREA {
  width: 100%;
  /* margin-left: 4.8%; */
  /* margin-right: 4.8%; */
  height: 100%;
  outline: 0;
}

.sqlHeader{
  margin-left: 5%;
  margin-right: 5%;
}
.API-REST {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.API-REST-COLLAPSE {
  padding-left: 1%;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #888888;
    margin-left: 6%;
    margin-right: 6%;
    background-color: rgb(255, 255, 255);
    /* border-radius: 10px; */
    margin-bottom: 1%;
    /* border: 1px solid; */
    border-radius: 10px;
    margin-top: -2%;
    z-index: 50;
    padding-top: 2%;
}

.API-REST-AUTH {
  border: 1px solid rgb(42, 44, 67);
  border-radius: 10px;
  background-color: white;
  /* margin-bottom: 1%; */
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  z-index: 100;
}
.API-REST-AUTH-METHOD {
  width: 10%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  border-radius: 15px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: rgb(23, 173, 238);
}
.API-REST-AUTH-URL {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.API-REST-AUTH-HEADER {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.API-REST-AUTH-BODY {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.API-REST-AUTH-ICON {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.API-REST-AUTH-METHOD h1 {
  font-size: 10px;
  margin: 0;
  color: white;
}
.API-REST-AUTH-URL h1 {
  font-size: 10px;
  margin: 0;
}
.API-REST-AUTH-HEADER h1 {
  font-size: 8px;
  margin: 0;
}
.API-REST-AUTH-BODY h1 {
  font-size: 8px;
  margin: 0;
}
.API-REST-AUTH-ICON h1 {
  font-size: 5%;
  margin: 0;
}

.input-todo {
  margin-bottom: 1%;
}
.input-todo input {
  outline: 0;
  border-width: 0 0 2px;
  background: none;
  border-color: rgb(23, 173, 238);
  margin-left: 1%;
  width: 48%;
}
.add-todo {
  margin-left: 1%;
  margin-right: 1%;
  padding: 0;
  justify-content: center;
  display: flex;
  width: 97%;
  background: none;
  color: rgb(42, 44, 67);
  border: 2px rgb(23, 173, 238);
  border-style: dashed solid;
  font-size: 20px;
  text-align: center;
  align-items: center;
}
.add-todo:hover {
  background: rgb(23, 173, 238);
  color: white;
  -webkit-transition: 1s;
  transition: 1s;
}
.add-todo:active {
  border: none;
}
.add-todo:focus {
  outline: none;
}
.API-REST-AUTH-URL-DATA input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgb(23, 173, 238);
  width: 100%;
}
.API-REST-AUTH-URL-DATA input:focus {
  border-color: rgb(42, 44, 67);
  -webkit-transition: 1s;
  transition: 1s;
}
.API-REST-AUTH-URL-DATA input::after {
  border-color: rgb(23, 173, 238);
}
.API-REST-AUTH-URL-DATA {
  display: flex;
  width: 100%;
  padding-top: 2%;
}
.API-REST-TITLE-URL {
  width: 5%;
    display: flex;
    align-items: center;
}
.API-REST-CONTENT-URL {
  width: 93%;
  margin-right: 2%;
}

.API-REST-CONTENT-BODY {
  width: 100%;
}
.API-REST-CONTENT-BODY textarea {
  /* overflow: auto; */
  /* resize: vertical; */
  width: 97%;
  margin-left: 1%;
  margin-right: 1%;
  /* margin-top: 0px; */
  /* margin-bottom: 0px; */
  /* height: 35px; */
  padding-left: 1%;
  /* padding-right: 0px; */
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgb(23, 173, 238);
  height: 150px;
  border-radius: 15px;
}

.API-REST-AUTH-HEADER-DATA{
  padding-top: 1%;
}
.API-REST button{
  margin-top: 1%;
  width: 200px;
  height: 50px;
  border-radius: 30px;
  padding: 0;
}

.RELATION {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  /* padding-left:5; */
}
.HEADER-TOP {
  height: 30%;
  background: white;
  width: 100%;
  padding-left: 2%;
  padding-top: 5.8%;
}
.RELATION-BODY {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: rgba(248, 248, 248, 1);
  height: 60%;
  justify-content: center;
  align-items: center;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  /* overflow-x: scroll; */
  /* overflow-x: hidden; */
}
.RELATION-HEADER {
  display: flex;
  width: 100%;
  /* background-color: blue; */
  height: 30%;
  justify-content: center;
  align-items: center;
}
.RELATION-INDEX {
  height: 20%;
  background-color: purple;
}
.RELATION-COLUMN {
  height: 1760px;
  /* overflow: hidden; */
  /* border-radius: 14px; */
  color: rgba(128, 129, 132, 1);
  padding-top: 1%;
  padding-bottom: 1%;
  margin-left: 1%;
  display: block;
  flex-wrap: wrap;
  width: 200px;
  /* background-color: white; */
  box-sizing: border-box;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  position: relative;
  /* float: left; */
}
.RELATION-COLUMN p {
  margin: 0;
}

.RELATION-COLUMN select {
  border-radius: 14px;
}
.REALATION-DATA {
  padding-top: 2%;
  padding-left: 5%;
  padding-right: 5%;
  margin: 5px;
  /* height: 80%; */
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: 14px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 2%;
  margin-bottom: 2%;
  margin-top: 4%;
  /* border-bottom-color: red; */
  background-color: white;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.RELATION-INFO {
  display: inline-flex;
  justify-content: left;
  width: 100vw;
  padding-right: 1%;
  overflow-y: scroll;
  /* padding-left: 0; */
  /* overflow-x: hidden; */
  height: 80%;
  /* padding-left: 0; */
}
.RELATION-ACTIONS button {
  width: 200px;
  height: 50px;
  border-radius: 30px;
}
.RELATION-ACTIONS {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  /* padding-top: 3%; */
}
#categorias {
  width: 100%;
  display: flex;
  height: 10%;
  justify-content: center;
  align-items: center;
}
#categorias div {
  margin-left: 1%;
  background-color: white;
  /* padding-left: 2%;
  padding-right: 2%; */
  padding-top: 1%;
  padding-bottom: 1%;
  cursor: pointer;
}
.block-info {
  /* background: rgba(56, 51, 51, 0.3); */
  position: absolute;
  width: 100%;
  height: 100%;
}
#cod_chamado:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#data_abertura:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#usuario_afetado:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#usuario_solicitante:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#descricao:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#status:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#sla_violado:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
.BADGES-STOPWORDS {
  /* border-radius: 20px; */
  height: 40px;
  width: auto;
  max-width: 400px;
  color: #fff;
  background-color: rgba(0, 104, 165, 1);
  margin-right: 1%;
  margin-left: 10px;
  margin-bottom: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 2%;
  font-size: 100%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  /* border-radius: 0.25rem; */
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.IMPORT-RIGHT-STOPWORDS {
  height: 60%;
  display: flex;
  /* box-sizing: border-box; */
  width: 100%;
  justify-content: left;
  background-color: rgba(248, 248, 248, 1);
  padding-top: 10%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  /* margin-left: 15px; */
  overflow-y: scroll;
}
.IMPORT-LEFT-FORM-STOP {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 50%;
  padding-top: 5%;
}
.IMPORT-LEFT-BUTTON-STOP {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  flex-wrap: wrap;
}
.IMPORT-LEFT-BUTTON-STOP button {
  border-radius: 30px;
  border: 0px;
  width: 200px;
  height: 50px;
  background-color: rgb(43, 44, 67);
}
.IMPORT-LEFT-BUTTON-STOP button:hover {
  background-color: rgb(23, 173, 238);
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
}
.STOPWORDS-BODY input {
  width: 100%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  padding-left: 1%;
}
.QTD-TOPICOS-BODY input {
  width: 100%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  padding-left: 1%;
}
.STOPWORDS-HEADER h1 {
  color: rgba(128, 129, 132, 1);
  font-size: 18px;
  width: 100%;
}
.STOPWORDS-HEADER{
  display: flex;
}
.QTD-TOPICOS-HEADER {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 2%;
}
.QTD-TOPICOS-HEADER h1 {
  color: rgba(128, 129, 132, 1);
  font-size: 18px;
  margin: 0;
}
.QTD-TOPICOS {
  margin-top: 10%;
}

.IMPORT-RIGHT-BUTTON button {
  border-radius: 30px;
  border: 0px;
  width: 200px;
  height: 50px;
  /* background-color: rgba(0, 165, 80, 1); */
}
.IMPORT-RIGHT-BUTTON button:hover {
  background-color: rgb(6, 63, 33);
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
}

.STOPWORDS-BODY input {
  background-color: rgb(248, 248, 248);
}
.QTD-TOPICOS-BODY input {
  background-color: rgb(248, 248, 248);
}

.TOPICOS {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* overflow: scroll; */
}
.TOPICOS-SCROLL {
      /* overflow: hidden; */
      height: 100%;
}
.TOPICOS-HEADER {
  height: 30%;
  background: white;
  width: 100%;
  padding-left: 2%;
  padding-top: 5.8%;
}
.TOPICOS-HEADER h1{
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 30px;
}
.TOPICOS-HEADER p{
  color: rgba(208, 208, 208, 1);
}
.TOPICOS-BODY {
  width: 100%;
  height: 70%;
  /* overflow: hidden; */
}
.TOPICOS-ACTIONS {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10%;
  align-items: center;
}
.TOPICOS-ACTIONS button {
  border-radius: 30px;
  border: 0px;
  width: 200px;
  height: 50px;
  background-color: rgba(0, 165, 80, 1);
}
.TOPICOS-ACTIONS button:hover {
  background-color: rgb(6, 63, 33);
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
}
.TOPICO-DATA-CARD-INFO {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* height: 100%; */
  justify-content: space-around;
  align-items: center;
  background-color: rgba(248, 248, 248, 1);
}

.TOPICO-DATA-CARD-TEXT {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  width: 100%;
  margin-left: 6%;
  margin-right: 6%;
  height: auto;
  padding: 1% 2% 1% 2%;
  margin-bottom: 1%;
  box-shadow: 0px 0px 10px rgba(231, 231, 232, 0.3);
  justify-content: space-between;
}
.TOPICO-DATA-CARD-TEXT-ONE {
  width: 55%;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
}
.TOPICO-DATA-CARD-TEXT-ONE h1 {
  color: rgba(0, 104, 165, 1);
  font-size: 16px;
  margin: 0;
}
.TOPICO-DATA-CARD-TEXT-ONE p {
  /* display: inline-block; */
  /* max-width: 80%; */
  /* height: 1.5em; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  width: 100%;
  /* color: rgba(128, 129, 132, 1); */
  font-size: 12px;
  margin: 0;
  color: rgba(128, 129, 132, 1);
}
.TOPICO-DATA-CARD-TEXT-TWO {
  width: 20%; 
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */

}
.TOPICO-DATA-CARD-FLEX {
  width: 90%;

}
.TOPICO-DATA-CARD-TEXT-TWO h1 {
  color: rgba(0, 104, 165, 1);
  font-size: 16px;
  margin: 0;
}
.TOPICO-DATA-CARD-TEXT-TWO input {
  outline: 0;
  border-width: 0 0 2px!important;
  border-color: rgba(128, 129, 132, 0.28)!important;
  display: inline-block;
  max-width: 80%;
  /* height: 1.5em; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: rgba(128, 129, 132, 1);
  font-size: 12px;
  margin: 0;
  border-radius: 0px!important;
}
.TOPICO-DATA-CARD-TEXT-THREE {
  width: 20%;
  height: 100%;
  display: block;
  align-items: center;
}
.TOPICO-DATA-CARD-TEXT-THREE h1 {
  color: rgba(0, 104, 165, 1);
  font-size: 16px;
  margin: 0;
  width: 100%;
}
.TOPICO-DATA-CARD-TEXT-THREE p {
  display: inline-block;
  max-width: 80%;
  /* height: 1.5em; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: rgba(128, 129, 132, 1);
  font-size: 12px;
  margin: 0;
}

.TOPICO-DATA-CARD-STATUS {
  width: 10%;
  height: 100%;
}
.TOPICO-DATA-CARD-STATUS-TITLE {
}
.TOPICO-DATA-CARD-STATUS-TITLE h1 {
  color: rgba(0, 104, 165, 1);
  font-size: 16px;
}
.TOPICO-DATA-CARD-STATUS-INFO {
}

@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.5b3aa501.eot);
  src: url(/static/media/icomoon.5b3aa501.eot#iefix) format("embedded-opentype"),
    url(/static/media/icomoon.6f0de09f.ttf) format("truetype"),
    url(/static/media/icomoon.8f7d46fd.woff) format("woff"),
    url(/static/media/icomoon.70ed44d3.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Grupo--1:before {
  content: "\e900";
  color: #d0d0d0;
}
.icon-Grupo--2:before {
  content: "\e901";
  color: #fa4d4d;
}
.icon-Grupo-44:before {
  content: "\e902";
  color: #d0d0d0;
}
.icon-Grupo-45:before {
  content: "\e902";
  color: yellow;
}
.icon-Grupo-68:before {
  content: "\e903";
  color: #00adee;
}
.icon-plus:before {
  content: "\e904";
  color: #00a550;
}
.icon-search:before {
  content: "\e905";
  color: #d0d0d0;
}
.icon-history:before {
  content: "\e906";
  color: #fff;
}
.icon-upload:before {
  content: "\e907";
  color: #fff;
  font-weight: 600;
}
.icon-team:before {
  content: "\e908";
  color: #fff;
  font-weight: 600;
}
.icon-logout:before {
  content: "\e909";
  color: #fff;
}
.icon-Grupo-50:before {
  content: "\e90a";
  color: #fff;
}
.icon-menu:before {
  content: "\e90b";
  color: #fff;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

