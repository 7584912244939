.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgba(23, 173, 238, 0.102);
  border-radius: 5px;
}

.Progress {
  background-color: rgb(42, 44, 67);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}
