@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?zf7w3q");
  src: url("fonts/icomoon.eot?zf7w3q#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?zf7w3q") format("truetype"),
    url("fonts/icomoon.woff?zf7w3q") format("woff"),
    url("fonts/icomoon.svg?zf7w3q#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Grupo--1:before {
  content: "\e900";
  color: #d0d0d0;
}
.icon-Grupo--2:before {
  content: "\e901";
  color: #fa4d4d;
}
.icon-Grupo-44:before {
  content: "\e902";
  color: #d0d0d0;
}
.icon-Grupo-45:before {
  content: "\e902";
  color: yellow;
}
.icon-Grupo-68:before {
  content: "\e903";
  color: #00adee;
}
.icon-plus:before {
  content: "\e904";
  color: #00a550;
}
.icon-search:before {
  content: "\e905";
  color: #d0d0d0;
}
.icon-history:before {
  content: "\e906";
  color: #fff;
}
.icon-upload:before {
  content: "\e907";
  color: #fff;
  font-weight: 600;
}
.icon-team:before {
  content: "\e908";
  color: #fff;
  font-weight: 600;
}
.icon-logout:before {
  content: "\e909";
  color: #fff;
}
.icon-Grupo-50:before {
  content: "\e90a";
  color: #fff;
}
.icon-menu:before {
  content: "\e90b";
  color: #fff;
}
