.Dropzone {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(42, 44, 67);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}
.FileInput {
  display: none;
}
.Highlight {
  background-color: rgba(23, 173, 238, 0.102);
}
.icon-upload-file::before{
  content: "\e907";
  font-size: 50px;
  color: rgb(42, 44, 67);
}
.icon-upload-file-hover::before{
  content: "\e907";
  font-size: 50px;
  color: rgb(254, 202, 49)
}