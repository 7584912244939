@import url("https://fonts.googleapis.com/css?family=Montserrat");
.TOPICOS {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* overflow: scroll; */
}
.TOPICOS-SCROLL {
      /* overflow: hidden; */
      height: 100%;
}
.TOPICOS-HEADER {
  height: 30%;
  background: white;
  width: 100%;
  padding-left: 2%;
  padding-top: 5.8%;
}
.TOPICOS-HEADER h1{
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 30px;
}
.TOPICOS-HEADER p{
  color: rgba(208, 208, 208, 1);
}
.TOPICOS-BODY {
  width: 100%;
  height: 70%;
  /* overflow: hidden; */
}
.TOPICOS-ACTIONS {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  height: 10%;
  -webkit-align-items: center;
  align-items: center;
}
.TOPICOS-ACTIONS button {
  border-radius: 30px;
  border: 0px;
  width: 200px;
  height: 50px;
  background-color: rgba(0, 165, 80, 1);
}
.TOPICOS-ACTIONS button:hover {
  background-color: rgb(6, 63, 33);
  transition-duration: 1s;
}
.TOPICO-DATA-CARD-INFO {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* height: 100%; */
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-items: center;
  align-items: center;
  background-color: rgba(248, 248, 248, 1);
}

.TOPICO-DATA-CARD-TEXT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: white;
  width: 100%;
  margin-left: 6%;
  margin-right: 6%;
  height: auto;
  padding: 1% 2% 1% 2%;
  margin-bottom: 1%;
  box-shadow: 0px 0px 10px rgba(231, 231, 232, 0.3);
  justify-content: space-between;
}
.TOPICO-DATA-CARD-TEXT-ONE {
  width: 55%;
  height: 100%;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
}
.TOPICO-DATA-CARD-TEXT-ONE h1 {
  color: rgba(0, 104, 165, 1);
  font-size: 16px;
  margin: 0;
}
.TOPICO-DATA-CARD-TEXT-ONE p {
  /* display: inline-block; */
  /* max-width: 80%; */
  /* height: 1.5em; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  width: 100%;
  /* color: rgba(128, 129, 132, 1); */
  font-size: 12px;
  margin: 0;
  color: rgba(128, 129, 132, 1);
}
.TOPICO-DATA-CARD-TEXT-TWO {
  width: 20%; 
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */

}
.TOPICO-DATA-CARD-FLEX {
  width: 90%;

}
.TOPICO-DATA-CARD-TEXT-TWO h1 {
  color: rgba(0, 104, 165, 1);
  font-size: 16px;
  margin: 0;
}
.TOPICO-DATA-CARD-TEXT-TWO input {
  outline: 0;
  border-width: 0 0 2px!important;
  border-color: rgba(128, 129, 132, 0.28)!important;
  display: inline-block;
  max-width: 80%;
  /* height: 1.5em; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: rgba(128, 129, 132, 1);
  font-size: 12px;
  margin: 0;
  border-radius: 0px!important;
}
.TOPICO-DATA-CARD-TEXT-THREE {
  width: 20%;
  height: 100%;
  display: block;
  align-items: center;
}
.TOPICO-DATA-CARD-TEXT-THREE h1 {
  color: rgba(0, 104, 165, 1);
  font-size: 16px;
  margin: 0;
  width: 100%;
}
.TOPICO-DATA-CARD-TEXT-THREE p {
  display: inline-block;
  max-width: 80%;
  /* height: 1.5em; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: rgba(128, 129, 132, 1);
  font-size: 12px;
  margin: 0;
}

.TOPICO-DATA-CARD-STATUS {
  width: 10%;
  height: 100%;
}
.TOPICO-DATA-CARD-STATUS-TITLE {
}
.TOPICO-DATA-CARD-STATUS-TITLE h1 {
  color: rgba(0, 104, 165, 1);
  font-size: 16px;
}
.TOPICO-DATA-CARD-STATUS-INFO {
}
