.PAGEFOUR-GRAFICO-TOP {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* flex-wrap: wrap; */
}

.PAGEFOUR-GRAFICO-CARD-ONE {
  height: 97%;
  width: 100%;
  background-color: white;
  /* border-radius: 15px; */
  overflow: hidden;
  /* border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; */
}
.PAGEFOUR-GRAFICO-CARD-ONE-CONTENT {
  display: flex;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.GRAPH-TABLE-HEADER {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 10px;
  color: white;
  background-color: rgb(43, 44, 67);
  align-items: center;
}

.GRAPHS-FOUR-TABLE {
  width: 100%;
  height: 7%;
  display: flex;
  flex-wrap: wrap;
}

.GRAPHS-FOUR-TABLE-CONTENT {
  height: 80%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.GRAPHS-FOUR-TABLE-ACTION {
  height: 80%;
  width: 100%;
}
.GRAPHS-FOUR-TABLE-ACTION button {
  border-radius: 50px;
}
.GRAPHS-FOUR-TABLE-BUTTONS {
  width: 150px;
  display: inline-block;
  overflow: auto;
  white-space: nowrap;
  margin: 0px auto;
}
.GRAPHS-FOUR-TABLE-BUTTONS-HIDDEN {
  display: none;
}
.GRAPHS-FOUR-CONTENT-DATA {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 10px;
  height: 10%;
  border-bottom-style: solid;
  /* max-width: 12.5%; */
  border-bottom-width: 1px;
}
.GRAPHS-FOUR-CONTENT-DATA div {
  max-width: 12.5%;
  font-size: 10px;
  min-width: 12.5%;
  text-align: center;
}
.GRAPH-TABLE-HEADER div {
  max-width: 12.5%;
  min-width: 12.5%;
  display: flex;
  justify-content: center;
  text-align: center;
}
