.API-REST {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.API-REST-COLLAPSE {
  padding-left: 1%;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #888888;
    margin-left: 6%;
    margin-right: 6%;
    background-color: rgb(255, 255, 255);
    /* border-radius: 10px; */
    margin-bottom: 1%;
    /* border: 1px solid; */
    border-radius: 10px;
    margin-top: -2%;
    z-index: 50;
    padding-top: 2%;
}

.API-REST-AUTH {
  border: 1px solid rgb(42, 44, 67);
  border-radius: 10px;
  background-color: white;
  /* margin-bottom: 1%; */
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: right;
  justify-content: right;
  cursor: pointer;
  z-index: 100;
}
.API-REST-AUTH-METHOD {
  width: 10%;
  height: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  /* border: 1px solid red; */
  border-radius: 15px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: rgb(23, 173, 238);
}
.API-REST-AUTH-URL {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.API-REST-AUTH-HEADER {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.API-REST-AUTH-BODY {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.API-REST-AUTH-ICON {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.API-REST-AUTH-METHOD h1 {
  font-size: 10px;
  margin: 0;
  color: white;
}
.API-REST-AUTH-URL h1 {
  font-size: 10px;
  margin: 0;
}
.API-REST-AUTH-HEADER h1 {
  font-size: 8px;
  margin: 0;
}
.API-REST-AUTH-BODY h1 {
  font-size: 8px;
  margin: 0;
}
.API-REST-AUTH-ICON h1 {
  font-size: 5%;
  margin: 0;
}

.input-todo {
  margin-bottom: 1%;
}
.input-todo input {
  outline: 0;
  border-width: 0 0 2px;
  background: none;
  border-color: rgb(23, 173, 238);
  margin-left: 1%;
  width: 48%;
}
.add-todo {
  margin-left: 1%;
  margin-right: 1%;
  padding: 0;
  -webkit-justify-content: center;
  justify-content: center;
  display: -webkit-flex;
  display: flex;
  width: 97%;
  background: none;
  color: rgb(42, 44, 67);
  border: 2px rgb(23, 173, 238);
  border-style: dashed solid;
  font-size: 20px;
  text-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.add-todo:hover {
  background: rgb(23, 173, 238);
  color: white;
  transition: 1s;
}
.add-todo:active {
  border: none;
}
.add-todo:focus {
  outline: none;
}
.API-REST-AUTH-URL-DATA input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgb(23, 173, 238);
  width: 100%;
}
.API-REST-AUTH-URL-DATA input:focus {
  border-color: rgb(42, 44, 67);
  transition: 1s;
}
.API-REST-AUTH-URL-DATA input::after {
  border-color: rgb(23, 173, 238);
}
.API-REST-AUTH-URL-DATA {
  display: flex;
  width: 100%;
  padding-top: 2%;
}
.API-REST-TITLE-URL {
  width: 5%;
    display: flex;
    align-items: center;
}
.API-REST-CONTENT-URL {
  width: 93%;
  margin-right: 2%;
}

.API-REST-CONTENT-BODY {
  width: 100%;
}
.API-REST-CONTENT-BODY textarea {
  /* overflow: auto; */
  /* resize: vertical; */
  width: 97%;
  margin-left: 1%;
  margin-right: 1%;
  /* margin-top: 0px; */
  /* margin-bottom: 0px; */
  /* height: 35px; */
  padding-left: 1%;
  /* padding-right: 0px; */
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgb(23, 173, 238);
  height: 150px;
  border-radius: 15px;
}

.API-REST-AUTH-HEADER-DATA{
  padding-top: 1%;
}
.API-REST button{
  margin-top: 1%;
  width: 200px;
  height: 50px;
  border-radius: 30px;
  padding: 0;
}
