.BADGES-STOPWORDS {
  /* border-radius: 20px; */
  height: 40px;
  width: auto;
  max-width: 400px;
  color: #fff;
  background-color: rgba(0, 104, 165, 1);
  margin-right: 1%;
  margin-left: 10px;
  margin-bottom: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 2%;
  font-size: 100%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  /* border-radius: 0.25rem; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.IMPORT-RIGHT-STOPWORDS {
  height: 60%;
  display: -webkit-flex;
  display: flex;
  /* box-sizing: border-box; */
  width: 100%;
  -webkit-justify-content: center;
  justify-content: left;
  background-color: rgba(248, 248, 248, 1);
  padding-top: 10%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  /* margin-left: 15px; */
  overflow-y: scroll;
}
.IMPORT-LEFT-FORM-STOP {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 50%;
  padding-top: 5%;
}
.IMPORT-LEFT-BUTTON-STOP {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  flex-wrap: wrap;
}
.IMPORT-LEFT-BUTTON-STOP button {
  border-radius: 30px;
  border: 0px;
  width: 200px;
  height: 50px;
  background-color: rgb(43, 44, 67);
}
.IMPORT-LEFT-BUTTON-STOP button:hover {
  background-color: rgb(23, 173, 238);
  transition-duration: 1s;
}
.STOPWORDS-BODY input {
  width: 100%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  padding-left: 1%;
}
.QTD-TOPICOS-BODY input {
  width: 100%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  padding-left: 1%;
}
.STOPWORDS-HEADER h1 {
  color: rgba(128, 129, 132, 1);
  font-size: 18px;
  width: 100%;
}
.STOPWORDS-HEADER{
  display: flex;
}
.QTD-TOPICOS-HEADER {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 2%;
}
.QTD-TOPICOS-HEADER h1 {
  color: rgba(128, 129, 132, 1);
  font-size: 18px;
  margin: 0;
}
.QTD-TOPICOS {
  margin-top: 10%;
}

.IMPORT-RIGHT-BUTTON button {
  border-radius: 30px;
  border: 0px;
  width: 200px;
  height: 50px;
  /* background-color: rgba(0, 165, 80, 1); */
}
.IMPORT-RIGHT-BUTTON button:hover {
  background-color: rgb(6, 63, 33);
  transition-duration: 1s;
}

.STOPWORDS-BODY input {
  background-color: rgb(248, 248, 248);
}
.QTD-TOPICOS-BODY input {
  background-color: rgb(248, 248, 248);
}
