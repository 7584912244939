.IMPORT {
  display: flex;
  justify-content: left;
  height: 100%;
  width: 100%;
}
.ERROR-IMPORT {
  z-index: 9000;
}
.IMPORT-LEFT {
  width: 25%;
  /* padding-left: 5%; */
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(248, 248, 248, 1);
  border-right: 1px solid rgba(128, 129, 132, 0.28);
  position: relative;
}
.IMPORT-LEFT-HEADER {
  height: 30%;
  background: white;
  width: 100%;
  padding-left: 5%;
  padding-top: 15%;
}
.HEADER-INFO p {
  color: rgba(208, 208, 208, 1);
}
.HEADER-INFO h1 {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 30px;
}
.IMPORT-LEFT-FORM {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 60%;
  padding-top: 5%;
}
.IMPORT-LEFT-FROM-NAME {
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);

  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(248, 248, 248, 1);
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.IMPORT-LEFT-FROM-OPERATION {
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0);

  display: block;
  width: 98%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(248, 248, 248, 1);
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.IMPORT-LEFT-FROM-LOGO {
  margin-bottom: 7%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  background-color: #fff;
  width: 100%;
  text-align: left;
  color: rgb(119, 120, 137);
  height: 33px;
}
.IMPORT-LEFT-FORM-OPERATIONS {
  padding-left: 5%;
  display: flex;
  margin: 0;
  align-items: center;
  width: 100%;
  justify-content: left;
  padding: 0;
  height: 10%;
  padding-left: 5%;
  padding-top: 1%;
}
.IMPORT-LEFT-BUTTON {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
}
.IMPORT-LEFT-BUTTON button {
  width: 200px;
  height: 50px;
  border-radius: 30px;
  padding: 0;
}
.IMPORT-RIGHT {
  display: block;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow-y: hidden;
}
.IMPORT-RIGHT-BLOCK {
  background: rgba(56, 51, 51, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 284;
}
.IMPORT-RIGHT-BUTTONS {
  height: 8%;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  -webkit-align-items: center;
  align-items: center;
  background-color: rgba(248, 248, 248, 1);
}
.IMPORT-RIGHT-BUTTON-1 {
  width: 228px;
}
.IMPORT-RIGHT-BUTTON-2 {
  width: 228px;
}
.IMPORT-RIGHT-BUTTON-3 {
  width: 228px;
}
.IMPORT-RIGHT-HEADER {
  height: 30%;
  background: white;
  width: 100%;
  padding-left: 5%;
  padding-top: 10%;
}
.IMPORT-RIGHT-HEADER p {
  color: rgba(208, 208, 208, 1);
}
.IMPORT-RIGHT-FORM {
  padding-top: 2.5%;
  width: 100%;
  height: 42%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(248, 248, 248, 1);
  flex-wrap: wrap;
  overflow-y: scroll;
}
.IMPORT-BUTTON-MIDDLE {
  margin-left: 1%;
  margin-right: 1%;
}
.IMPORT-RIGHT-BUTTONS-ACTION {
  height: 30%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.IMPORT-RIGHT-BUTTON {
  height: 10%;
  background-color: rgba(248, 248, 248, 1);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.IMPORT-LEFT-BUTTON-STOP-IMPORT {
  border-radius: 25px;
  width: 200px;
  height: 50px;
}
.IMPORT-LEFT-BUTTON-STOP-SALVAR {
  border-radius: 25px;
  width: 200px;
  height: 50px;
}
.operations-info {
  padding-left: 10%;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 2%;
  padding-top: 1.5%;
  padding-bottom: 1%;
  width: 100%;
}
.operations-info label {
  color: #808184;
  font-size: 16px;
}
.operations-options {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
}
.IMPORT-RIGHT-Button {
  display: flex;
  height: 10%;
  justify-content: center;
  align-items: center;
}
