.SQL {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  height: 100%;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
}
.SQL-HEADER {
  width: 100%;
  height: 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.SQL-CONNECT {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: space-evenly;
}
.SQL-AUTH {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-left: 5%;
  margin-right: 5%;
  align-items: center;
}
.SQL-BODY {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  align-items: center;
  height: 70%;
}
.SQL button{
  width: 200px;
  height: 50px;
  border-radius: 30px;
  padding: 0;
  margin-top: 1%;
}
.SQL-INPUT-ONE {
  width: 100%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  padding-left: 1%;
}
.SQL-INPUT-TWO {
  width: 40%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(128, 129, 132, 0.28);
  padding-left: 1%;
}
.SQL-INPUT-TWO-SELECT {
  display: block;
  width: 100%;
  word-wrap: normal;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  outline: 0;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border-color: rgba(128, 129, 132, 0.28);
  background-clip: padding-box;
  /* border: 1px solid #ced4da; */
  border-width: 0 0 2px;
  /* border-radius: 0.25rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.SQL-TEXT-AREA {
  width: 100%;
  /* margin-left: 4.8%; */
  /* margin-right: 4.8%; */
  height: 100%;
  outline: 0;
}

.sqlHeader{
  margin-left: 5%;
  margin-right: 5%;
}