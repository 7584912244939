.activePagination{
    z-index: 1;
    color: #fff!important;
    background-color: #4ba846!important;
    border-color: #4ba846!important;
}
.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #2b2c42;
    background-color: #fff;
    border: 1px solid #dee2e6;
}