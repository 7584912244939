.RELATION {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  /* padding-left:5; */
}
.HEADER-TOP {
  height: 30%;
  background: white;
  width: 100%;
  padding-left: 2%;
  padding-top: 5.8%;
}
.RELATION-BODY {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: rgba(248, 248, 248, 1);
  height: 60%;
  justify-content: center;
  align-items: center;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  /* overflow-x: scroll; */
  /* overflow-x: hidden; */
}
.RELATION-HEADER {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  /* background-color: blue; */
  height: 30%;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.RELATION-INDEX {
  height: 20%;
  background-color: purple;
}
.RELATION-COLUMN {
  height: 1760px;
  /* overflow: hidden; */
  /* border-radius: 14px; */
  color: rgba(128, 129, 132, 1);
  padding-top: 1%;
  padding-bottom: 1%;
  margin-left: 1%;
  display: block;
  flex-wrap: wrap;
  width: 200px;
  /* background-color: white; */
  box-sizing: border-box;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  position: relative;
  /* float: left; */
}
.RELATION-COLUMN p {
  margin: 0;
}

.RELATION-COLUMN select {
  border-radius: 14px;
}
.REALATION-DATA {
  padding-top: 2%;
  padding-left: 5%;
  padding-right: 5%;
  margin: 5px;
  /* height: 80%; */
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: 14px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 2%;
  margin-bottom: 2%;
  margin-top: 4%;
  /* border-bottom-color: red; */
  background-color: white;
  user-select: none;
}
.RELATION-INFO {
  display: inline-flex;
  justify-content: left;
  width: 100vw;
  padding-right: 1%;
  overflow-y: scroll;
  /* padding-left: 0; */
  /* overflow-x: hidden; */
  height: 80%;
  /* padding-left: 0; */
}
.RELATION-ACTIONS button {
  width: 200px;
  height: 50px;
  border-radius: 30px;
}
.RELATION-ACTIONS {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  /* padding-top: 3%; */
}
#categorias {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  height: 10%;
  justify-content: center;
  align-items: center;
}
#categorias div {
  margin-left: 1%;
  background-color: white;
  /* padding-left: 2%;
  padding-right: 2%; */
  padding-top: 1%;
  padding-bottom: 1%;
  cursor: pointer;
}
.block-info {
  /* background: rgba(56, 51, 51, 0.3); */
  position: absolute;
  width: 100%;
  height: 100%;
}
#cod_chamado:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#data_abertura:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#usuario_afetado:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#usuario_solicitante:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#descricao:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#status:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}
#sla_violado:hover{
  /* background-color: #fff!important; */
  border: 2px solid gray!important;
}